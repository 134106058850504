<!-- <template>
  <div class="filterView">
    <div class="cell-contianer" v-for="(item, index) in items" :key="index">
      <div v-if="index < limitCount" class="flex-row cell">
        <div class="title">{{ item.title }}:</div>
        <div class="flex-row child-container">
          <div
            v-show="j < maxChilds"
            :class="['child', selectsObj[item.key] === child.id ? 'child-on' : '']"
            v-for="(child, j) in item.children"
            :key="j"
            @click="selectCell(item, child)"
          >
            {{ child.name }}
          </div>
          <div v-if="index === 1" @click="foldBtnClick" class="showMore">
            <span>{{ isOpening ? "收起" : "全部展开" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> -->
<template>
  <div class="filterView">
    <div class="cell-contianer" v-for="(item, index) in items" :key="index">
      <div v-if="index < limitCount && item" class="flex-row cell">
        <div class="title">{{ item.title || "" }}:</div>
        <div class="flex-row child-container">
          <div v-show="j < maxChilds"
            :class="['child',selectsObj[item.key] === child.childId ? 'child-on' : '',]"
            v-for="(child, j) in item.children"
            :key="j"
            @click="selectCell(item, child)"
          >
            {{ index === 1 ? (child.status == 0 ?  child.name:'') : child.name }}
          </div>
		  <div v-if="index === 1 &&  item.children.length > 10" @click="foldBtnClick" class="showMore">
		    <span>{{ isOpening ? "收起" : "全部展开" }}</span>
		  </div>
        </div>
      </div>
    </div>
<!--    <div class="fold-btn" @click="foldBtnClick">
      <span>{{ isOpening ? "收起" : "全部展开" }}</span>
      <Icon
        :type="isOpening ? 'ios-arrow-up' : 'ios-arrow-down'"
        size="16"
        color="rgba(45, 129, 255, 1);"
      />
    </div> -->
  </div>
</template>
<script>
import { Loading } from "element-ui";

//  项目申报 组件
export default {
  name: "filterView",
  props: {
    tabIndex: {
      type: String,
      required: true,
    },
  },
  watch: {
	  // 注释
    // tabIndex: {
    //   immediate: true,
    //   handler(val) {
    //     console.log("tabIndex:", val);
    //     if (val === "0" || val === "1") {
    //       this.loadTypeData(val);
    //     } else {
    //       this.loadSuqiuData();
    //     }
    //   },
    // },
		tabIndex: {
		  immediate: true,
		  handler(val) {
		    console.log("tabIndex:", val);
		    if (val === "0") {
		      this.loadTypeData("1");
		    } else {
		      this.loadSuqiuData();
		    }
		  },
		},
  },
  data() {
    return {
	  maxChilds: 16,
      limitCount: 10,
      isOpening: false,
      selectsObj: {}, // 选择的key值，用于传回父类
      items: [
        {
          title: "主题分类",
          key: "sxdl",
          children: [],
        },
		{
		  title: "主管部门",
		  key: "deptCode",
		  children: [],
		},
		{
		  title: "项目年度",
		  key: "year",
		  children: [
		    { childId: null, name: "不限" },
			{ childId: '2023', name: "2023年",value:'2023'},
			{ childId: '2022', name: "2022年",value:'2022'},
		    { childId: '2021', name: "2021年",value:'2021'},
		    { childId: '2020', name: "2020年",value:'2020'},
		    { childId: '2019', name: "2019年",value:'2019'},
		    { childId: '2018', name: "2018年",value:'2018'},
		    { childId: "2017", name: "2017年",value:'2011'},
		  ],
		},
      ],
    };
  },
  created() {
    this.loadDeptList(); // 获取部门
  },
  methods: {
    loadTypeData(el) {
      let url = ''
      if ( el === "0" ) {
        url = '/dev-api/permissionsort/list'
      } else if( el === "1" ) {
        url = '/dev-api/permissionsort/listzjl'
      }

      this.$httpApi
        .get( url , {
          pageNum: 1,
          pageSize: 100,
          itemtype: parseInt(this.tabIndex) + 1, //1行政审批 2资金扶持
        })
        .then((res) => {
          console.log("事项分类列表>>>", res);
          if (res.code == 200) {
            let list = res.data;
            // let result = [];
            let result = [{ childId:null, name: "不限",sortcode:null }];
            for (const i in list) {
              if (Object.hasOwnProperty.call(list, i)) {
                const element = list[i];
                element.childId = element.sortcode;
                element.name = `${element.sortname}(${element.total})`;
                result.push(element);
              }
            }
            this.$set(this.items, 0, {
              title: "主题分类",
              key: "sxdl",
              children: result,
            });
            this.initSelect();
          }
        })
        .catch((e) => {});
    },
    loadSuqiuData() {
      this.$httpApi
        .get("/dev-api/basicinfo/type/list", {
          pageNum: 1,
          pageSize: 100,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log("12大类", res);
            let list = res.rows;
            // let result = [];
            let result = [{ childId:null , name: "不限",sortcode:null }];
            for (const i in list) {
              if (Object.hasOwnProperty.call(list, i)) {
                const element = list[i];
                element.childId = element.id;
                result.push(element);
              }
            }
            this.getNum(result);
          }
        })
        .catch((e) => {});
    },
    getNum(resultList) {
      this.$httpApi
        .post("/dev-api/sqxdtjfx/data/find/sxqdtjfx_sql_countbyfwdx", {
          pageNum: 1,
          pageSize: 100,
        })
        .then((res) => {
          console.log("获取数量", res);
          if (res.data.length > 0) {
            let resList = res.data;
            for (const item of resultList) {
              for (const el of resList) {
                if (item.itemTypeCode === el.item_type_code) {
                  item.total = el.total;
                  item.name = `${item.typeName}(${item.total})`;
                }
              }
            }
            this.$set(this.items, 0, {
              title: "主题分类",
              key: "sxdl",
              children: resultList,
            });
            this.initSelect();
          }
        });
    },
    // 主管部门
    loadDeptList() {
      let param = {
        deptType:"1328"
      }
      this.$httpApi.get("/dev-api/system/dept/list", param).then((res) => {
        console.log("dept list:", res);
        if (res.code == 200) {
          let result = [{ childId: 0,status:"0",  name: "不限" }];
          let list = res.rows;
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              element.childId = element.deptId;
              element.name = element.deptName;
              result.push(element);
            }
          }
          this.$set(this.items, 1, {
            title: "主管部门",
            key: "deptCode",
            children: result,
          });
          this.initSelect();
        }
      })
	  .catch((err) => {

	  });
    },
    initSelect() {
      for (const i in this.items) {
        const item = this.items[i];
        if (item.children && item.children.length > 0) {
          // 默认都选中第0个
          this.selectsObj[item.key] = item.children[0].childId;
        }
      }
      if( this.selectsObj.sxdl ) {
        console.log("选中的:", this.selectsObj);
        this.$emit("changeSelect", this.selectsObj);
      }
    },
    selectCell(item, child) {
      this.selectsObj[item.key] = child.childId;
      this.$forceUpdate();
      let data = {};
      for (const key in this.selectsObj) {
        if (Object.hasOwnProperty.call(this.selectsObj, key)) {
          const value = this.selectsObj[key];
          if (value != 0) {
            // 第一列 不限的就不传
            data[key] = value;
          }
        }
      }
      console.log("<<<------------value，大类----------------->>>");
      this.$emit("changeSelect", data);
    },
    foldBtnClick() {
      this.isOpening = !this.isOpening;
      if (this.isOpening) {
        this.maxChilds = 1008611;
      } else {
        this.maxChilds = 15;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  padding: 6px 0;
  color: #888888;
  font-size: 13px;
  font-family: "Microsoft YaHei";
  border-bottom: 1px dotted #bbbbbb;
  font-weight: 500;
  .title {
    margin-right: 30px;
    color: #000;
    font-size: 15px;
    flex-shrink: 0;
  }
  .child-container {
    flex-wrap: wrap;
    .child {
      margin-right: 16px;
      cursor: pointer;
      flex-shrink: 0;
      padding: 8px 0;
    }
    .child-on {
      color: rgba(45, 129, 255, 1);
    }
  }
}
.fold-btn {
  padding: 8px 0;
  //   border-radius: 8px;
  background-color: #f1f1f1;
  color: rgba(45, 129, 255, 1);
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
.showMore {
  padding: 0 5px;
  border: 1px solid rgba(45, 129, 255, 1);
  border-radius: 5px;
  color: rgba(45, 129, 255, 1);
}
</style>
