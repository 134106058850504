<template>
  <div class="find-list-container">
    <div class="banner-size">
      <div class="banner-limit banner-info" style="position: relative">
        <!-- <span class="banner-fMax">项目申报</span> -->
        <!-- <span class="banner-fMin banner-other"
        >送政策、送服务，助推企业发展</span
        > -->
        <!-- 三大类切换 -->
        <el-tabs class="tabsthree-container" v-model="activeTab" @tab-click="handleTabsEdit">
          <el-tab-pane v-for="(item, i) in tabs" :key="i" :name="i + ''">
            <span slot="label"
              >{{ item.name }}
              {{ item.number != null ? "(" + item.number + ")" : "" }}</span
            >
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="findList width-limit" ref="findList">
      <!--筛选-->
      <div class="find-tabs">
        <!-- <el-tabs v-model="activeTab">
          <el-tab-pane v-for="(item, i) in tabs" :key="i" :name="i + ''">
            <span slot="label">{{ item.name }}({{ item.number }})</span>
          </el-tab-pane>
        </el-tabs> -->
        <FindFilterView
          :tabIndex="activeTab"
          @changeSelect="changeSelect"
        ></FindFilterView>
      </div>
      <!--内容部分主题-->
      <SectionHeader
        title="项目申报"
        :totalSize="totalSize"
        placeholder="请输入专项名称"
        @clickButton="searchByKeyword"
      >
        <div slot="left">
          <el-row>
            <el-button size="small" type="info" plain
              >最新<i class="el-icon-bottom"></i
            ></el-button>
            <el-button size="small" type="info" plain
              >热度<i class="el-icon-bottom"></i
            ></el-button>
            <el-button size="small" type="info" plain
              >金额<i class="el-icon-bottom"></i
            ></el-button>
          </el-row>
        </div>
      </SectionHeader>

      <!--内容部分-->
      <div ref="findListContent" class="find-list-items-container">
        <div class="item" v-for="(item, i) in showList" :key="i">
          <DeclareCell
            :key="item.id"
            :collectItem="isItemCollect(item)"
            :item="item"
            :isShowText="false"
            @clickCell="clickCell"
            @clickIsFollow="clickIsFollow"
          ></DeclareCell>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :page-size="pagerSize"
          :total="totalSize"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!--登录提示弹窗-->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>需要登录才能收藏，是否登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="gotoLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片
import FindFilterView from "./components/findFilter";
import SectionHeader from "@/components/sectionHeader";
import { Loading } from "element-ui";

export default {
  name: "findList",
  components: {
    FindFilterView,
    DeclareCell,
    SectionHeader,
  },
  data() {
    return {
      dialogVisible: false, //登录提示弹窗
      timeItem: "全部",
      targetArray: [],
      showList: [],
      pagerSize: 12, // 3行 4列
      totalSize: 0,
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      tabs: [
        {
          name: "行政审批类",
        },
        {
          name: "资金扶持类",
        },
        {
          name: "企业诉求类",
        },
      ],
      sortcode: "", // 事项大类id
      deptCode: '', // 部门名称
      keyword: "", //搜索框输入的专项名称，用于搜索\
      year: "", //年份筛选
      sxmc: null, //事项名称筛选
      collectList: [],
    };
  },
  mounted() {
    this.getCollectList();
    this.getType();
	  this.getSPFCList();
  },
  methods: {
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.$store.getters.getUserInfo) {
        let identify = this.$store.getters.getUserInfo.shxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //比对收藏列表,判断是否收藏
    isItemCollect(item) {
      let list = this.collectList;

      for (let i = 0; i < list.length; i++) {
        let collectItem = list[i];
        if (item.id == collectItem.collectionId) {
          console.log(item.name + ":已收藏");
          return collectItem;
        }
      }
      return null;
    },
    /* 按部门和类型统计事项数 */
    getType() {
      let loadingpolicySupport = Loading.service({
        target: this.$refs.findList,
      });
      this.$httpApi
        .post("/dev-api/sqxdtjfx/data/find/sxqdtjfx_countbytype", {})
        .then((res) => {
          if (res.data.length > 0) {
            loadingpolicySupport.close();
            let data = res.data[0];
            this.getTypeNum(data.sq);
          }
        })
        .catch((err) => {
          loadingpolicySupport.close();
        });
    },
    /* 获取行政审批，资金扶持类数量 */
    getTypeNum(sqnum) {
      console.log("诉求" + sqnum);
      this.$httpApi
        .post("/dev-api/sqxdtjfx/data/find/sxqdtjfx_sql_countBySort", {})
        .then((res) => {
          console.log("类型统计总数:", res);
          if (res.data.length > 0) {
            let data = res.data;
            this.tabs = [
				// 注释行政审批
              // {
              //   name: "行政审批类",
              //   number: data[0].total || 0,
              // },
              {
                name: "资金扶持类",
                number: data[1].total || 0,
              },
              {
                name: "企业诉求类",
                number: sqnum || 0,
              },
            ];
          }
        })
        .catch((err) => {});
    },
    /**
     * 审批 and 扶持 裂变信息获取
     *
     * @param {}
     */
    getSPFCList() {
      this.getCollectList();
      let loadingInstance = Loading.service({
        target: this.$refs.findListContent,
      });
      let url = "";
      // let dlnm = +this.activeTab + 1 + ""; // 事项类型（1：审批类 2：产业资金类 3：诉求类）
	  // 注释行政审批
	  let dlnm = +this.activeTab + 2 + "";
      let params = {
        pageNum: 1,
        pageSize: 100,
        itemtype: dlnm,
        year: this.year,
        sxmc: this.sxmc,
        // sortcode : this.sortcode,
      };
      if (["1", "2"].indexOf(dlnm) >= 0) {
        url =
          dlnm === "1"
            ? "/dev-api/basicinfo/baseitem/xmsbList"
            : "/dev-api/basicinfo/baseitem/zjl/xmsbList";
        params.sfsq = "1";
        params.sortcode = this.sortcode;
        if (this.deptCode !== 0 && this.deptCode != undefined) {
          params.deptCode = this.deptCode;
        }
      } else {
        url = "/dev-api/basicinfo/baseitem/shuqiu/xmsbList";
        params.itempCode = this.sortcode;
        if (this.deptCode !== 0 && this.deptCode != undefined) {
          params.deptCode = this.deptCode;
        }
      }
      console.log("请求参数>>>", params);
	 
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("办事列表，审批类，扶持类>>>", res);
          loadingInstance.close();
          if (res.code == 200) { 
            let result = [];
            result = res.rows;
            this.totalSize = result.length;
            this.targetArray = this.grouping(result, this.pagerSize);
            this.showList = this.targetArray[0];
          } else {
          }
        })
        .catch((e) => {
          loadingInstance.close();
        });
    },
    grouping(array, n) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += n)));
      }
      console.log("处理后的数组：", newArray);
      return newArray;
    },
    /* 分页切换时，更换显示的数组 */
    handleCurrentChange(e) {
      // console.log("页面转跳", e);
      this.showList = this.targetArray[e - 1];
    },
    /**
     * 大类切换
     *
     * @param { String } sxdl [ e.sxdl 大类编码 ]
     */
    changeSelect(e) {
      console.log("changeSelect,index>>>", e);
      this.sortcode = e.sxdl;
      this.deptCode = e.deptCode;
      this.year = e.year;
      this.getSPFCList(); // 刷新数据
      // this.loadData(e);
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /*
     * 监听DeclareCeil子组件点击获取详情事件
     */
    clickCell(item) {
      console.log("选择的是:", item);
      // alert("转跳")
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideShenpi",
          query: {
            name: item.name,
            itemType: item.itemtype,
			isAdded: item.isAdded
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 根据专项名称关键字搜索
     */
    searchByKeyword(data) {
      this.sxmc = data;
      this.getSPFCList();
    },
    /**
     * 选择时间
     * @param item
     */
    handleTime(item) {
      console.log("item:", item);
      this.timeItem = item;
      //发送请求获取数据
    },
	handleTabsEdit(){
		this.sortcode = ''
		this.getSPFCList()
	}
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 15px;
    }
  }

  .section-header {
    border-bottom: 4px solid #000;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 24px;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 46px;
    line-height: 46px;
  }

  .rank-section {
    padding-top: 10px;
    font-size: 13px;
  }

  .find-list-items-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 40px 0;
    .item {
      width: calc((1200px - 60px) / 4); // 每行4列，则有3个间距 每个间距20px
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .item:nth-child(4n + 4) {
      margin-right: 0px;
    }
  }

  .pagination {
    margin: 30px auto;
    text-align: center;
  }

  .find-tabs {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
.find-list-container {
  /* 顶部tabs */
  .tabsthree-container {
    position: absolute;
    bottom: -1px;
    left: 0px;

    .el-tabs__header {
      margin: 0;
      padding: 0;
    }

    .el-tabs__item {
      width: 395px;
      height: 3.75rem;
      line-height: 3.75rem;
      font-size: 20px;
      background: rgba(#fff, 0.4);
      color: #fff;
      text-align: center;
      margin-right: 10px;
      // padding: 20 px;

      &:hover {
        background: #fff;
        color: #000;
        border-bottom: 0;
      }
    }

    .is-active {
      background: #fff;
      color: #000;
      border-bottom: 0;
    }

    .el-tabs__active-bar {
      width: 0 !important;
      height: 0 !important;
    }
  }

  .el-input {
    input {
      background-color: #FFFFFF;
      border-radius: 0px !important;
    }
  }

  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 360px;
    display: flex;

    .el-button {
      border: 0px;
      border-radius: 0px;
      width: 90px;

      .el-icon-bottom {
        margin-left: 15px;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }

  .el-dropdown-link {
    margin-bottom: 20px;
  }

  .sortList {
    /*margin-top: 20px;*/
    display: flex;

    .select-time {
      margin-right: 200px;
    }
  }

  .choseTime {
    height: 10px;
    width: 150px;
  }
}
</style>
